(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:projectValuationGridKpiSettings
   * @description
   * Allows to edit the KPIs shown in the KPI section of the grid list. The area is defined via the area parameter in the dialog open event
   * 
   * @requires DXSPolluxApp.component:projectValuationMain
   */
  angular.module("DXSPolluxApp")
    .component("projectValuationGridKpiSettings", {
      template: require('html-loader!./project.valuation.grid.kpi.settings.tmpl.html'),
      require: {
        "main": "^projectValuationMain"
      },
      bindings: {
        "data": "<"
      },
      controllerAs: "vm",
      controller: ['$compile', '$scope', '$timeout', '$q', 'projectValuationGridKpiSettingsService', '$translate', projectValuationGridKpiSettingDialogCtrl]
    });

  function projectValuationGridKpiSettingDialogCtrl($compile, $scope, $timeout, $q, projectValuationGridKpiSettingsService, $translate) {
    var vm = this;
    //#region variables

    vm.loaded = false;

    vm.availableKpisDataSource = new kendo.data.HierarchicalDataSource({
      data: []
    });

    vm.selectedKpisDataSource = new kendo.data.HierarchicalDataSource({
      data: []
    });

    vm.kpiAddDisabled = false;
    vm.kpiRemoveDisabled = false;

    vm.selectOptionsAvailableKpis = {
      dataTextField: "descriptionEnglish",
      dataValueField: "id",
      connectWith: "selectedKpis",
      dataSource: vm.availableKpisDataSource,
      template: availableKpiTemplate,
      dataBound: compileTemplate, //http://dojo.telerik.com/@bubblemaster/IQatA/2
      add: compileTemplate
    };

    vm.selectOptionsSelectedKpis = {
      dataTextField: "descriptionEnglish",
      dataValueField: "id",
      draggable: {
        enabled: true
      },
      reorder: kpiReordered,
      dataSource: vm.selectedKpisDataSource,
      template: selectedKpiTemplate,
      dataBound: compileTemplate, //http://dojo.telerik.com/@bubblemaster/IQatA/2
      add: compileTemplate
    };

    //#endregion

    //#region function declaration
    vm.kpiPlusButtonClicked = kpiPlusButtonClicked;
    vm.kpiMinusButtonClicked = kpiMinusButtonClicked;
    //#endregion

    //#region function implementation

    vm.$onInit = function () {
      vm.area = vm.data.area;
      vm.areaTypeId = vm.data.areaTypeId;
      vm.loaded = false;

      $timeout(function () {
        loadData(true, false);
      });
    };

    function compileTemplate(e) {
      var listbox = this;
      $timeout(function () {
        var rows = listbox.items();
        rows.each(function (index, row) {
          if (!$(row).hasClass("ng-scope")) {
            $compile(row)($.extend($scope.$new(), listbox.dataItem(row)));
          }
        });
      });
    }

    function availableKpiTemplate(dataItem) {
      return `
<div class="item-row">
  <div class="item-text">
    ${dataItem.descriptionEnglish} 
  </div>
  <span class="item-button k-icon k-i-plus" ng-click="vm.kpiPlusButtonClicked(${dataItem.id})" ng-class="{'k-state-disabled': vm.kpiAddDisabled}"></span>
</div> 
`;
    }

    function selectedKpiTemplate(dataItem) {
      return `
<div class="item-row">
  <span class="k-icon k-i-reorder drag-clue-hidden"></span>
  <div class="item-text item-text-cyan">
    ${dataItem.descriptionEnglish} 
  </div>
  <span class="item-button k-icon k-i-minus drag-clue-hidden" ng-click="vm.kpiMinusButtonClicked(${dataItem.id})" ng-class="{'k-state-disabled': vm.kpiRemoveDisabled}"></span>
</div> 
`;
    }

    function kpiPlusButtonClicked(id) {
      var kpi = vm.availableKpisDataSource.data().filter((item) => { return item.id === id; });
      if (kpi && kpi.length) {
        vm.availableKpisDataSource.data(vm.availableKpisDataSource.data().filter((item) => { return item.id !== id; }));
        vm.selectedKpisDataSource.data().push(kpi[0]);
      }
      vm.kpiAddDisabled = vm.selectedKpisDataSource.data().length >= 4;
      vm.kpiRemoveDisabled = vm.selectedKpisDataSource.data().length === 1;
    }

    function kpiMinusButtonClicked(id) {
      var kpi = vm.selectedKpisDataSource.data().filter((item) => { return item.id === id; });
      if (kpi && kpi.length) {
        vm.selectedKpisDataSource.data(vm.selectedKpisDataSource.data().filter((item) => { return item.id !== id; }));
        vm.availableKpisDataSource.data().push(kpi[0]);
      }
      vm.kpiAddDisabled = vm.selectedKpisDataSource.data().length >= 4;
      vm.kpiRemoveDisabled = vm.selectedKpisDataSource.data().length === 1;
    }

    function kpiReordered(e) { //https://docs.telerik.com/kendo-ui/knowledge-base/list-box-reordering-not-working?_ga=2.70954064.1141527551.1594992121-1555797772.1579010099
      e.preventDefault();
      var dataSource = e.sender.dataSource;

      var dataItem = e.dataItems[0]
      var index = dataSource.indexOf(dataItem) + e.offset;
      dataSource.remove(dataItem);
      dataSource.insert(index, dataItem);
      e.sender.wrapper.find("[data-uid='" + dataItem.uid + "']").addClass("k-state-selected");
    }
    
    function loadData(openDialog, forceReload, callback) {
      $q.all([
        projectValuationGridKpiSettingsService.load(forceReload)
      ]).then(function () {
        var selectedKpis = projectValuationGridKpiSettingsService.getUserKpiSettings().filter((kpi) => { return kpi.area === vm.area && kpi.areaTypeId === vm.areaTypeId; }).sort((kpi1, kpi2) => kpi1.sortOrder - kpi2.sortOrder);
        vm.selectedKpisDataSource.data(selectedKpis);

        vm.kpiReference = projectValuationGridKpiSettingsService.getAvailableKpis();

        // only show KPIs of the same area (and areaTypeId !!) that are not already selected
        vm.availableKpisDataSource.data(
          vm.kpiReference.filter((kpi) => { return kpi.area === vm.area && kpi.areaTypeId === vm.areaTypeId && !selectedKpis.some(selectedKpi => selectedKpi.id === kpi.id); }).sort((kpi1, kpi2) => kpi1.sortOrder - kpi2.sortOrder)
        );

        vm.availableKpisDataSource.fetch();
        vm.selectedKpisDataSource.fetch();

        $timeout(function () {
          vm.kpiAddDisabled = vm.selectedKpisDataSource.data().length >= 4;
          vm.kpiRemoveDisabled = vm.selectedKpisDataSource.data().length === 1;

          vm.loaded = true;
          
          callback && callback();

        }, 10);
      });
    }

    //#endregion

    //#region events
    $scope.$on('closeSidebar', function () {
      if (vm.loaded) {
        var selectedKpis = vm.selectedKpisDataSource.data();
        var selectedUserKpis = [];
        for (var i = 0; i < selectedKpis.length; ++i) {
          var kpi = JSON.parse(JSON.stringify(selectedKpis[i]));
          kpi.sortOrder = i;

          selectedUserKpis.push(kpi);
        }

        projectValuationGridKpiSettingsService.updateKpiSettings(vm.area, vm.areaTypeId, selectedUserKpis);
      }
    });
    //#endregion
  }
}());
