(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:projectValuationSidebar
   * 
   * @requires DXSPolluxApp.component:projectValuationMain
   */
  angular.module("DXSPolluxApp")
    .component("projectValuationSidebar", {
      template: require('html-loader!./project.valuation.sidebar.tmpl.html'),
      require: {
        "main": "^projectValuationMain"
      },
      bindings: {
        "searchMode": "<"
      },
      controllerAs: "vm",
      controller: ['$scope', '$rootScope', '$timeout', 'fileDownloadService', 'appSettings', 'appLocalization', 'appStorage', 'appSidebarActionsService', 'angSynapsesDashboardEventsService', 'angSynapsesDashboardService', 'multiDashboardService', projectValuationSidebarCtrl]
    });

  function projectValuationSidebarCtrl($scope, $rootScope, $timeout, fileDownloadService, appSettings, appLocalization, appStorage, appSidebarActionsService, angSynapsesDashboardEventsService, angSynapsesDashboardService, multiDashboardService) {
    var vm = this;
    vm.isProjectSelected = () => !!appStorage.getSelectedProject();
    vm.isOrganizationSelected = () => !!appStorage.getOrganizationGuid() && appStorage.getOrganizationGuid() !== '00000000-0000-0000-0000-000000000000';

    const mds = multiDashboardService;

    //#region variables    
    vm.showBiggerSidebarFlag = true;
    vm.activeChevron = '';
    vm.menu = null;
    vm.showMenu = false;
    vm.appLocalization = appLocalization;
    vm.autoCollapseTimeout = 3000;
    vm.biggerSidebarLocked = false;
    vm.mouseInArea = false;
    vm.collapseTimerPromise = null;
    vm.togglePropertyDetails = false;
    vm.togglePropertyData = false;
    vm.toggleJobsNewJobs = false;
    vm.toggleJobsTemplate = false;
    vm.toggleJobDetails = false;
    vm.toggleJobsData = false;
    vm.toggleJobsEdit = false;
    vm.togglePropertyEdit = false;
    vm.toggleRentRollPropertyEdit = false;
    vm.toggleRentRollJobEdit = false;
    vm.toggleDashboardEdit = false;
    vm.toggleDashboardLayout = false;
    vm.toggleDashboardData = false;
    vm.toggleProjectEditMode = false;
    vm.Area = {
      Organization: 'tabOrganization',
      Projects: 'tabProjectstd',
      Properties: 'tabProperty',
      Jobs: 'tabValuationPortfolio',
      Rentrolls: 'tabRentroll',
      JobRentrolls: 'tabJobRentroll',
      Dashboard: 'tabDashboard',
      MultiDashboard: 'tabMultiDashboard'
    };

    vm.areaSelectedItem = {};
    vm.areaSelectedItem[vm.Area.Properties] = null;
    vm.areaSelectedItem[vm.Area.Jobs] = null;

    vm.clickedMenuEntry = null;
    vm.groupingItemSelected = null;

    vm.actions = [];

    vm.multiDashboardActive = false;
    vm.displayAssumtionWidgets = false;

    appSidebarActionsService.register(function (actions) {
      vm.actions = actions;
    }, function () {
      vm.actions = [];
    });

    //#endregion

    //#region function declaration
    vm.showBiggerSidebar = showBiggerSidebar;
    vm.showSmallerSidebar = showSmallerSidebar;
    vm.activateArea = activateArea;
    vm.onActionSelected = onActionSelected;
    vm.onCloseMenu = onCloseMenu;
    vm.smallerSidebarWidth = 50;
    vm.biggerSidebarWidth = 139;
    vm.toggleBiggerSidebarLocked = toggleBiggerSidebarLocked;
    vm.sidebarMouseEnter = sidebarMouseEnter;
    vm.sidebarMouseLeave = sidebarMouseLeave;
    vm.cancelCollapseTracking = cancelCollapseTracking;
    vm.shouldShowChevron = shouldShowChevron;
    vm.actionClicked = actionClicked;
    vm.onGroupingItemSelected = onGroupingItemSelected;
    vm.switchChevronIcon = switchChevronIcon;
    vm.resetAllToggle = resetAllToggle;
    vm.editProperty = editProperty;
    vm.editJob = editJob;
    vm.goToProjects = goToProjects;
    vm.goToOrganizations = goToOrganizations;
    vm.activateDashboard = activateDashboard;
    vm.toggleDashboardAssumptions = toggleDashboardAssumptions;
    //#endregion

    mds.multiDashboardActive$.subscribe((active) => $timeout(() => vm.multiDashboardActive = active));
    mds.showDashboardAssumptions$.subscribe((show) => $timeout(() => vm.displayAssumtionWidgets = show));

    //#region function implementation
    vm.$onInit = function () {
      vm.biggerSidebarLocked = appStorage.getSidebarLocked();

      if (vm.biggerSidebarLocked) {
        vm.showBiggerSidebar();
      } else {
        vm.showSmallerSidebar();
      }

      vm.activeChevron = vm.Area.Projects;

      angSynapsesDashboardEventsService.hostCommand$.subscribe((cmd) => {
        switch (cmd.command) {
          case 'dashboard-sets-edit':
            $timeout(() => {
              vm.activeChevron = vm.Area.Dashboard;
              vm.toggleDashboardEdit = vm.switchChevronIcon(!cmd.params, 'dashboardEdit');
            });
            break;
        }
      });
    }

    function showBiggerSidebar() {
      vm.main.resizePaneSidebar('#project-valuation-searchbar', vm.biggerSidebarWidth + 'px');
      vm.showBiggerSidebarFlag = true;
      $rootScope.$broadcast('sidebar-big', vm.showBiggerSidebarFlag);
    }

    function collapseSidebar() {
      //if (vm.showBiggerSidebarFlag && !vm.biggerSidebarLocked) {
      //  if (!vm.mouseInArea) {
      //    showSmallerSidebar();
      //  } else {
      //    //mouse still in sidebar
      //    vm.collapseTimerPromise = $timeout(collapseSidebar, vm.autoCollapseTimeout);
      //  }
      //}
    }

    function toggleBiggerSidebarLocked() {
      vm.biggerSidebarLocked = !vm.biggerSidebarLocked;
      appStorage.setSidebarLocked(vm.biggerSidebarLocked);
      if (!vm.biggerSidebarLocked) {
        vm.showSmallerSidebar();
      }
    }

    function goToProjects() {
      appStorage.setSelectedProject(null);
      activateArea(vm.Area.Projects);
    }

    function goToOrganizations() {
      appStorage.setOrganizationGuid('00000000-0000-0000-0000-000000000000');
      activateArea(vm.Area.Organization);
    }

    function cancelCollapseTracking() {
      if (vm.collapseTimerPromise) {
        $timeout.cancel(vm.collapseTimerPromise);
        vm.collapseTimerPromise = null;
      }
    }

    function sidebarMouseEnter() {
      vm.mouseInArea = true;
      //cancelCollapseTracking();
    }

    function sidebarMouseLeave() {
      vm.mouseInArea = false;
      //cancelCollapseTracking();
      //vm.collapseTimerPromise = $timeout(collapseSidebar, vm.autoCollapseTimeout);
    }

    function showSmallerSidebar() {
      vm.main.resizePaneSidebar('#project-valuation-searchbar', vm.smallerSidebarWidth + 'px');
      vm.showBiggerSidebarFlag = false;
      $rootScope.$broadcast('sidebar-big', vm.showBiggerSidebarFlag);
    }

    function activateArea(area) {
      if (area === vm.Area.Projects) {
        appStorage.setSelectedProject(null);
      }
      if (area === vm.Area.Organization) {
        appStorage.setOrganizationGuid('00000000-0000-0000-0000-000000000000');
        appStorage.setSelectedProject(null);
      }

      vm.activeChevron = area;
      $rootScope.$broadcast('valuationTabSelect', area);
      if (area === 'tabRentroll') {
        $rootScope.$broadcast('tabRentrollSelect');
      }
      if (area === 'tabJobRentroll') {
        $rootScope.$broadcast('tabJobRentrollSelect');
      }
    }

    function activateDashboard() {
      vm.activeChevron = 'tabDashboard';
      $rootScope.$broadcast("showDashboardSelect");
    }

    function shouldShowChevron(area) {
      switch (area) {
        case vm.Area.Rentrolls:
          return vm.areaSelectedItem[vm.Area.Properties] !== null && vm.activeChevron !== 'tabProjectstd' && vm.activeChevron !== 'tabValuationPortfolio' && vm.activeChevron !== 'tabJobRentroll' && vm.activeChevron !== 'tabDashboard';
        case vm.Area.JobRentrolls:
          return vm.areaSelectedItem[vm.Area.Jobs] !== null && vm.activeChevron !== 'tabProjectstd' && vm.activeChevron !== 'tabProperty' && vm.activeChevron !== 'tabRentroll' && vm.activeChevron !== 'tabDashboard';
        case vm.Area.Dashboard:
          return (vm.activeChevron === 'tabDashboard') || (vm.areaSelectedItem[vm.Area.Jobs] !== null && vm.activeChevron !== 'tabProjectstd' && vm.activeChevron !== 'tabProperty' && vm.activeChevron !== 'tabRentroll' && vm.activeChevron !== 'tabJobRentroll');
        case vm.Area.MultiDashboard:
          return shouldShowChevron(vm.Area.Dashboard) && vm.multiDashboardActive;
        case vm.Area.Properties:
          return vm.areaSelectedItem[vm.Area.Properties] !== null;//??? Never used
        case vm.Area.Jobs:
          return vm.activeChevron !== vm.Area.Projects && vm.isProjectSelected();
        default:
          return true;
      }
    }

    function onCloseMenu(event) {
      if (!event.item.tagName) { //making sure we are closing the actual menu and not a submenu
        vm.showMenu = false;
      }

      if (vm.clickedMenuEntry) {
        var action = angular.element(vm.clickedMenuEntry).attr('data-action');
        if (!action) {
          event.preventDefault();
        }
        vm.clickedMenuEntry = null;
      }
    }

    function onActionSelected(action) {
      const self = this;
      switch (action) {
        case 'newInvestmentJob': {
          $rootScope.$broadcast("createValuationPortfoliosData:jobType:1", vm.areaSelectedItem[vm.Area.Properties]);
          break;
        }
        case 'newValuationJob': {
          $rootScope.$broadcast("createValuationPortfoliosData:jobType:2", vm.areaSelectedItem[vm.Area.Properties]);
          break;
        }
        case 'newCustomJob': {
          $rootScope.$broadcast("createValuationPortfoliosData:jobType:4", vm.areaSelectedItem[vm.Area.Properties]);
          break;
        } 
        case 'newCustomPortfolioJob': {
          $rootScope.$broadcast("createValuationPortfoliosData:jobType:5", vm.areaSelectedItem[vm.Area.Properties]);
          break;
        }
        case 'newProperty': {
          $rootScope.$broadcast("createPropertiesData", null);
          break;
        }
        case 'exportInvestmentTemplate': {
          exportTemplate(1, 'CRE Investment Template.xlsx');
          break;
        }
        case 'exportValuationTemplate': {
          exportTemplate(2, 'Valuation Template.xlsx');
          break;
        }
        case 'openProperty': {
          $rootScope.$broadcast("editPropertiesData.inline", { id: vm.areaSelectedItem[vm.Area.Properties].Oid, data: vm.areaSelectedItem[vm.Area.Properties] });
          break;
        }
        case 'managesImages': {
          $rootScope.$broadcast("editPropertiesData.inline", { id: vm.areaSelectedItem[vm.Area.Properties].Oid, data: vm.areaSelectedItem[vm.Area.Properties], mode: 'imageManagement' });
          break;
        }
        case 'newCRREJobForProperty': {
          $rootScope.$broadcast("createValuationPortfoliosData:jobType:1", vm.areaSelectedItem[vm.Area.Properties]);
          break;
        }
        case 'newValJobForProperty': {
          $rootScope.$broadcast("createValuationPortfoliosData:jobType:2", vm.areaSelectedItem[vm.Area.Properties]);
          break;
        }
        case 'newCustomJobForProperty': {
          $rootScope.$broadcast("createValuationPortfoliosData:jobType:4", vm.areaSelectedItem[vm.Area.Properties]);
          break;
        }
        case 'newCustomPortfolioJobForProperty': {
          $rootScope.$broadcast("createValuationPortfoliosData:jobType:5", vm.areaSelectedItem[vm.Area.Properties]);
          break;
        }
        case 'showJobs': {
          $rootScope.$broadcast("valuationTabSelect", 'tabValuationPortfolio');
          break;
        }
        case 'importRentRoll': {
          $rootScope.$broadcast('propertiesData.canceled', {});//close so we can show import dialog Bug #8408
          $rootScope.$broadcast('showDqs', {
            businessObjectType: 'PropertyRentRoll',
            oid: vm.areaSelectedItem[vm.Area.Properties].Oid,
            dqsVersion: 2
          });
          break;
        }
        case 'exportRentRoll': {
          $rootScope.$broadcast('openRentRollExportDialog', vm.areaSelectedItem[vm.Area.Properties]);
          break;
        }
        case 'saveProperty': {
          $rootScope.$broadcast('savePropertiesData');
          break;
        }
        case 'removeProperty': {
          $rootScope.$broadcast('deleteProperty', vm.areaSelectedItem[vm.Area.Properties]);
          break;
        }
        case 'openJob': {
          $rootScope.$broadcast("editValuationPortfoliosData.inline", { id: vm.areaSelectedItem[vm.Area.Jobs].Oid, data: vm.areaSelectedItem[vm.Area.Jobs] });
          break;
        }
        case 'showPropertyFromJob': {
          vm.main.onElementSelected('tabValuationPortfolio', vm.areaSelectedItem[vm.Area.Jobs]);
          $rootScope.$broadcast("valuationTabSelect", 'tabProperty');
          break;
        }
        case 'upload': {
          const params = {
            item: vm.areaSelectedItem[vm.Area.Jobs],
            callback: function () {
              $rootScope.$broadcast('PortfolioLoadData');
            }
          };
          $rootScope.$broadcast("importCalcModelJob", params);
          break;
        }
        case 'dashboard-upload-calc-model': {
          const params = {
            item: vm.areaSelectedItem[vm.Area.Jobs],
            callback: function () {
              self.onActionSelected('dashboardDiscard');
            }
          };
          $rootScope.$broadcast("importCalcModelJob", params);
          break;
        }
        case 'download': {
          $rootScope.$broadcast('exportISPINITCalculationModelJob', vm.areaSelectedItem[vm.Area.Jobs]);
          break;
        }
        case 'saveJob': {
          $rootScope.$broadcast('savevaluationPortfoliosData:jobType:' + vm.areaSelectedItem[vm.Area.Jobs].JobTypeId);
          break;
        }
        case 'copyJob': {
          $rootScope.$broadcast('copyJob', {
            item: vm.areaSelectedItem[vm.Area.Jobs],
            withModel: false
          });
          break;
        }
        case 'copyJobWithCalculationModel': {
          $rootScope.$broadcast('copyJob', {
            item: vm.areaSelectedItem[vm.Area.Jobs],
            withModel: true
          });
          break;
        }
        case 'archiveJob': {
          const jobId = vm.areaSelectedItem[vm.Area.Jobs];
          if (jobId) {
            $rootScope.$broadcast('archivePortfolio', jobId);
          }

          break;
        }
        case 'markDashboard': {
          //angSynapsesDashboardEventsService.execDashboard('paint-start', { instanceIdentifier: 'mainDashboard' });
          break;
        }
        case 'newProject': {
          $rootScope.$broadcast('createNewProject');
          break;
        }
        case 'saveProjectName': {
          $rootScope.$broadcast('saveProjectName');
          break;
        }
        case 'dashboardSave': {
          angSynapsesDashboardService.setSaveFlag(true);
          $rootScope.$broadcast('dashboardSaveClicked');
          break;
        }
        case 'dashboardCopyJob': {
          let sourceJob = vm.areaSelectedItem[vm.Area.Jobs];
          sourceJob = sourceJob || mds.getMainJob();//if grid is refreshed (we got null), get main comparision job from multi dashboard
          const callback = (createdJobId) => {
            mds.addByJobId(parseInt(createdJobId));
          };

          $rootScope.$broadcast('copyJob', {
            item: sourceJob,
            withModel: true,
            dontOpenDialog: true,
            setSourceJobId: true,
            callback: callback
          });

          break;
        }
        case 'dashboardDiscard': {
          $rootScope.$broadcast('hideDashboard');
          $timeout(() => {
            $rootScope.$broadcast('showDashboardSelect');
          });
          break;
        }
      }
    }

    function exportTemplate(templateTypeId, exportFileName) {
      $rootScope.$broadcast('loadingIndicator', true);
      var downloadUrl = appSettings.environment.DQS_API_URL + "/import/calcmodel/v2/template?templateTypeId=" + templateTypeId;
      fileDownloadService.initiateDownload(downloadUrl, exportFileName);
    }

    function actionClicked(action) {
      if (action.callback) {
        action.callback();
      }
    }

    function onGroupingItemSelected(groupingItem) {
      switch (groupingItem) {
        case 'jobDetails':
          vm.toggleJobDetails = vm.switchChevronIcon(vm.toggleJobDetails, groupingItem);
          break;
        case 'jobData':
          vm.toggleJobsData = vm.switchChevronIcon(vm.toggleJobsData, groupingItem);
          break;
        case 'jobEdit':
          vm.toggleJobsEdit = vm.switchChevronIcon(vm.toggleJobsEdit, groupingItem);
          break;
        case 'jobTemplates':
          vm.toggleJobsTemplate = vm.switchChevronIcon(vm.toggleJobsTemplate, groupingItem);
          break;
        case 'jobNewJob':
          vm.toggleJobsNewJobs = vm.switchChevronIcon(vm.toggleJobsNewJobs, groupingItem);
          break;
        case 'propertyDetails':
          vm.togglePropertyDetails = vm.switchChevronIcon(vm.togglePropertyDetails, groupingItem);
          break;
        case 'rentRollData':
          vm.toggleRentRollData = vm.switchChevronIcon(vm.toggleRentRollData, groupingItem);
          break;
        case 'propertyEdit':
          vm.togglePropertyEdit = vm.switchChevronIcon(vm.togglePropertyEdit, groupingItem);
          break;
        case 'rentRollPropertyEdit':
          vm.toggleRentRollPropertyEdit = vm.switchChevronIcon(vm.toggleRentRollPropertyEdit, groupingItem);
          break;
        case 'rentRollJobEdit':
          vm.toggleRentRollJobEdit = vm.switchChevronIcon(vm.toggleRentRollJobEdit, groupingItem);
          break;
        case 'dashboardEdit':
          {
            if (vm.groupingItemSelected !== 'dashboardEdit') {
              angSynapsesDashboardEventsService.execDashboard('edit-start', { instanceIdentifier: 'mainDashboard' });
              vm.toggleDashboardEdit = vm.switchChevronIcon(true, groupingItem);
              vm.groupingItemSelected = 'dashboardEdit';
            }
            break;
          }
        case 'dashboardLayout':
          vm.toggleDashboardLayout = vm.switchChevronIcon(vm.toggleDashboardLayout, groupingItem);
          break;
        case 'dashboardData':
          vm.toggleDashboardData = vm.switchChevronIcon(vm.toggleDashboardData, groupingItem);
          break;
        default:
          vm.groupingItemSelected = null;
      }
    }

    function switchChevronIcon(flag, groupingItem) {
      vm.resetAllToggle();
      if (flag) {
        vm.groupingItemSelected = null;
        return false;
      } else {
        vm.groupingItemSelected = null;
        vm.groupingItemSelected = groupingItem;
        return true;
      }
    }

    function resetAllToggle() {
      vm.togglePropertyDetails = false;
      vm.togglePropertyData = false;
      vm.toggleJobsNewJobs = false;
      vm.toggleJobsTemplate = false;
      vm.toggleJobDetails = false;
      vm.toggleJobsData = false;
      vm.toggleJobsEdit = false;
      vm.togglePropertyEdit = false;
      vm.toggleRentRollPropertyEdit = false;
      vm.toggleRentRollJobEdit = false;
      vm.toggleDashboardEdit = false;
      vm.toggleDashboardLayout = false;
      vm.toggleDashboardData = false;
    }
    function editProperty() {
      $rootScope.$broadcast('editPropertyData');
    }

    function editJob() {
      $rootScope.$broadcast("editValuationPortfoliosData.inline", { id: vm.areaSelectedItem[vm.Area.Jobs].Oid, data: vm.areaSelectedItem[vm.Area.Jobs] });
    }

    function toggleDashboardAssumptions($event) {
      mds.toggleDashboardAssumptions();
      $event.preventDefault();
      $event.stopPropagation();
      $event.stopImmediatePropagation();
    }

    //#endregion

    //#region events
    $rootScope.$on('valuationsidebar.activateArea', function (event, area) {
      vm.toggleProjectEditMode = false;
      vm.groupingItemSelected = null;
      //area = tabValuationPortfolio, tabProperty, tabRentroll, tabProjectstd
      vm.activateArea(area);
    });

    $rootScope.$on('valuationTabSelected', function (event, area) {
      //area = tabValuationPortfolio, tabProperty, tabRentroll, tabProjectstd
      vm.activeChevron = area;
      if (area === vm.Area.Projects) {
        //here in case we activate projects using broadcast message, not by activateArea fn
        appStorage.setSelectedProject(null);
      }
    });

    $scope.$on('minimizeSidebar', function (event, params) {
      vm.showSmallerSidebar();
    });

    $scope.$on('maximizeSidebar', function (event, params) {
      vm.showBiggerSidebar();
    });

    $scope.$on('activateDashboardAreaAfterCopy', function (event, param) {
      $timeout(() => {
        vm.areaSelectedItem[vm.Area.Jobs] = param;
      });
    });

    $scope.$on('getSmallerSidebarSize', function (event, params) {
      params && params.callback && params.callback(vm.smallerSidebarWidth);
    });

    $rootScope.$on('appStorage.setSelectedProperty', function (event, item) {
      vm.areaSelectedItem[vm.Area.Properties] = item;
      if (item) {
        vm.areaSelectedItem[vm.Area.Jobs] = null;
        vm.areaSelectedItem[vm.Area.JobRentrolls] = null;
      }
    });

    $rootScope.$on('appStorage.setSelectedProject', function (event, item) {
      vm.areaSelectedItem[vm.Area.Properties] = null;
      vm.areaSelectedItem[vm.Area.Jobs] = null;
      vm.areaSelectedItem[vm.Area.JobRentrolls] = null;
    });

    $rootScope.$on('appStorage.setSelectedPortfolio', function (event, item) {
      vm.areaSelectedItem[vm.Area.Jobs] = item;
    });

    function handleJobEditMessage(editable) {
      resetAllToggle();
      vm.toggleJobsEdit = editable;
      if (editable) {
        vm.groupingItemSelected = 'jobEdit';
      }
    }

    $rootScope.$on('editValuationPortfolioDataFromMask:jobType:1', function (event, editable) { //CRE
      handleJobEditMessage(editable);
    });

    $rootScope.$on('editValuationPortfolioDataFromMask:jobType:2', function (event, editable) { //VAL
      handleJobEditMessage(editable);
    });

    $rootScope.$on('editValuationPortfolioDataFromMask:jobType:4', function (event, editable) { //Custom
      handleJobEditMessage(editable);
    });

    $rootScope.$on('editPropertyDataFromMask', function (event, editable) {
      resetAllToggle();
      vm.togglePropertyEdit = editable;
      if (editable) {
        vm.groupingItemSelected = 'propertyEdit';
      }
    });

    $rootScope.$on('ProjectEditMode', function (event, params) {
      $timeout(function () {
        vm.toggleProjectEditMode = params.enable;
      });
    });

    $rootScope.$on('sidebar:activateDashboard', function (event, params) {
      activateDashboard();
    });

    //#endregion
  }
}());

