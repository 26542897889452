//#####################################
//#####################################
/* for LOCAL DEV for now use both false (local API / WinAuth) */
const useMsal: boolean = true;
//#####################################
//#####################################

//ISPINIT B2C
const tenantId = 'ac2a9222-54b0-49fd-82a8-10d66ba9f011';
const clientId = '0cc12a53-73d4-4b33-ba84-1defa9155c92';//B2C_V2_ComeTogetherLIVE
const userFlow: string = 'B2C_1_applications_signin';
const azureAuthority: string = `https://ispinitsynapses.b2clogin.com/ispinitsynapses.onmicrosoft.com/${userFlow}`;
const azureAuthorityDomain: string = 'ispinitsynapses.b2clogin.com';
const azureScopes = [`${clientId}`, 'openid', 'profile'];
const azureProtectedResourceMap: Map<string, Array<string>> = new Map([
  ['https://polluxapi.synapses.ispinit.de', azureScopes],
  ['https://comparablesapi.synapses.ispinit.de', azureScopes],
  ['https://projectvaluationapi.synapses.ispinit.de', azureScopes],
  ['https://settingsapi.synapses.ispinit.de', azureScopes],
  ['https://dqsapi.synapses.ispinit.de', azureScopes],
  ['https://ioapi.synapses.ispinit.de', azureScopes],
  ['https://notificationsapi.synapses.ispinit.de', azureScopes]
]);
const azureRedirectUri: string = 'https://synapses.ispinit.de';
const azurePostLogoutRedirectUri: string = '';

export const environment = {
  production: false,
  UseMsal: useMsal,

  POLLUX_API_URL: 'https://polluxapi.synapses.ispinit.de/api/v2/00000000-0000-0000-0000-000000000000/00000000-0000-0000-0000-000000000000',
  POLLUX_COMPARABLES_API_URL: 'https://comparablesapi.synapses.ispinit.de/api/v2/00000000-0000-0000-0000-000000000000/00000000-0000-0000-0000-000000000000',
  POLLUX_PROJECTVALUATION_API_URL: 'https://projectvaluationapi.synapses.ispinit.de/api/v2/00000000-0000-0000-0000-000000000000/00000000-0000-0000-0000-000000000000',
  DQS_API_URL: 'https://dqsapi.synapses.ispinit.de/api/v2/00000000-0000-0000-0000-000000000000/00000000-0000-0000-0000-000000000000',
  DMS_IO_URL: 'https://ioapi.synapses.ispinit.de/api/v2/00000000-0000-0000-0000-000000000000/00000000-0000-0000-0000-000000000000',
  DMS_HELPTEXT_API_URL: 'https://helptextsapi.synapses.ispinit.de/api/v2/00000000-0000-0000-0000-000000000000/00000000-0000-0000-0000-000000000000',
  DMS_NOTIFICATION_API_URL: 'https://notificationsapi.synapses.ispinit.de/api/v2/00000000-0000-0000-0000-000000000000/00000000-0000-0000-0000-000000000000',
  COME_TOGETHER_URL: 'https://cometogether.ispinit.de',


  /* AZURE */
  AzureTenentId: tenantId,
  AzureClientId: clientId,
  AzureAuthority: azureAuthority,
  AzureAuthorityDomain: azureAuthorityDomain,
  AzureScopes: azureScopes,
  AzureRedirectUri: azureRedirectUri,
  AzurePostLogoutRedirectUri: azurePostLogoutRedirectUri,
  AzureProtectedResourceMap: azureProtectedResourceMap,
  /*COMMON*/
  GOOGLE_MAPS_API_URL: 'https://maps.google.com/maps/api/js?v=3&key=AIzaSyCEAvh-RyDaU-9ctZLznQYdUpmt86TFQ0E',
  SpreadJsLicenseKey: 'synapses.ispinit.de,726417593713533#B0ZVmYLZFexkjTQhWVZ9mbMdzbzVUNmZzQklTUKFWQ4lkWndDNilzNElTQLdjZvRHRMN6Z5tCZQZFd9VEVBZVbOhVTMJVRzlEaMVTdrZnRNZHVPhTcmFGMZZ7LvFUVy5WVOpXdDFjV9kEeMF5dzM7d5M7R9hkU6E6VwQVSiBnQJRXMUdmNlJ5SSdzd4clQxJHOO3Gc8kERpR6ZKpUYCh4L5dmTHhkeJdlQjhmbnNTN8gHWk9WWNJ7YKFTQM34biRmYUVGWJJFUnRWR84mcUNleoxERjNGZo5karkmel36KF94R6t6ajFXOllVe6dXWiojITJCLicjQCZkM6MkNiojIIJCL7gDN7UTOxEzN0IicfJye35XX3JSVPNjNiojIDJCLiMTMuYHITpEIkFWZyB7UiojIOJyebpjIkJHUiwiIzETN4ITMgEDMyETMyAjMiojI4J7QiwiIlRmL4lmbpB7cp9yclNHch9WezJiOiMXbEJCLigkYtdEIUlkTJB5UJJiOiEmTDJCLiMzM5MTM7MTO5cTM4YjM7IiOiQWSiwSflNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TP7hXQY9mRpFFZ9FXb6VmcolVd9ZTdztGV82keVJVWwIVVrskVzoGS9JHRHRTO9VlVDxEW486ZroHZ7AjaohlY6AVYKJTYs3SZjFXcsxWUxp7Z8cmVrtkRroneHdlRul6KoRjZLRUMLBZQ',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
