(function () {

  "use strict"

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:projectValuationWorkplace
   * @description
   * Workplace for the Project Valuation (Valuation Portfolio) area. This component is responsible for coordinating all BO tabs that are available in this area.
   * 
   * The tabs are configured in app/config/ProjectValuationTabs.json
   * 
   * @requires $rootScope
   * @requires DXSPolluxApp.service:appSettings
   * @requires DXSPolluxApp.service:appStorage
   * @requires $http
   * @requires $scope
   * @requires $translate
   * @requires $timeout
   * @requires DXSPolluxApp.component:projectValuationMain
   * 
   * @param {Object} searchMode The current search mode
   */
  angular.module("DXSPolluxApp")
    .component("projectValuationWorkplace", {
      template: require('html-loader!./project.valuation.workplace.tmpl.html'),
      bindings: {
        "searchMode": "="
      },
      require: {
        "main": "^projectValuationMain"
      },
      controllerAs: "vm",
      controller: ['$rootScope', 'appSettings', 'appStorage', '$http', '$scope', '$translate', '$timeout', '$q', 'projectValuationGridKpiSettingsService', 'appConfirmDialogService', 'multiDashboardService', projectValuationWorkplaceCtrl]
    });

  function projectValuationWorkplaceCtrl($rootScope, appSettings, appStorage, $http, $scope, $translate, $timeout, $q, projectValuationGridKpiSettingsService, appConfirmDialogService, multiDashboardService) {
    var vm = this;
    vm.isProjectSelected = () => !!appStorage.getSelectedProject();
    vm.isOrganizationSelected = () => !!appStorage.getOrganizationGuid() && appStorage.getOrganizationGuid() !== '00000000-0000-0000-0000-000000000000';

    vm.storage = appStorage;
    vm.mds = multiDashboardService;
    vm.tabOptions = null;
    vm.tabComponent = null;
    vm.showTabs = false;

    vm.tabConfig = null;
    vm.onSearchClicked = null;

    vm.tabStripDelay = undefined;

    vm.selectedTabHierarchy = null;
    vm.selectedTabHierarchyItemType = null;
    vm.selectedTabHierarchyItem = null;
    vm.tabs = [];
    vm.dqsData = null;
    vm.dashboardData = null;
    vm.isDashboardComparisonMode = false;

    vm.tabOptions = {
      scrollable: false,
      activate: function (e) {
        $scope.$apply(function () {
          vm.searchMode = angular.element(e.item)[0].getAttribute('data-searchmode') || '';
          $rootScope.$broadcast("toolbarSetArea", vm.searchMode);
          $rootScope.$broadcast('valuationTabSelected', e.item.id);
        });
      },
      animation: {
        open: {
          effects: "fadeIn"
        }
      },
      select: function (e) {
        vm.selectedImportArea = angular.element(e.item)[0].getAttribute('data-export-area-name') || '';
        vm.main.setSelectedImportArea(angular.element(e.item)[0].getAttribute('data-export-area-name') || '');

        vm.main.setPVInfoBarVisibility(false);
      }
    };


    //#region function declarations

    vm.onTabCreated = onTabCreated;
    vm.onElementSelected = onElementSelected;
    vm.onElementDeselected = onElementDeselected;
    vm.onShowAll = onShowAll;
    vm.setCounterValue = setCounterValue;
    vm.onSearchClicked = onSearchClicked;
    vm.doExport = doExport;
    vm.doGridExcelExport = doGridExcelExport;
    vm.closeDqs = closeDqs;
    vm.closeDashboard = closeDashboard;
    vm.dashboardGoForward = dashboardGoForward;
    vm.dashboardGoBack = dashboardGoBack;
    //#endregion

    /**
     * @ngdoc function
     * @name $onInit
     * @methodOf DXSPolluxApp.component:projectValuationWorkplace
     * @description
     * Loads the tab configuration file and initializes the workplace.
     */
    vm.$onInit = function () {
      vm.main.onWorkplaceCreated(this);
      $http.get('config/ProjectValuationTabs.json').then(function (result) {
        vm.tabConfig = result.data;
        //vm.searchMode = vm.tabConfig[0].searchMode;
        vm.tabs = new Array(vm.tabConfig.length);
        vm.tabStripDelay = true;  // http://docs.telerik.com/kendo-ui/AngularJS/Troubleshooting/notes-on-ng-repeat
      });
    };

    /**
     * @ngdoc function
     * @name onSearchClicked
     * @methodOf DXSPolluxApp.component:projectValuationWorkplace
     * @description
     * Called if the user clicked the search icon in the tab bar. Resets the search mode and shows all.
     * 
     * @param {Object} tab The tab in the tab bar where the search icon was clicked
     */
    function onSearchClicked(tab) {
      vm.onShowAll();
      $rootScope.$broadcast("resetValuationSearchMode");
      $rootScope.$broadcast("toolbarSetArea", tab.searchMode);
    }

    /**
     * @ngdoc function
     * @name setCounterValue
     * @methodOf DXSPolluxApp.component:projectValuationWorkplace
     * @description
     * Sets the counter value for a tab. The counter is displayed in the bottom left section.
     * 
     * @param {Object} tab The tab whose counter value will be set
     * @param {Integer} value The counter value (amount of data)
     */
    function setCounterValue(tab, value) {
      vm.main.setCounterValue(tab, value);
      if (String(tab) === 'projectstd') {
        if (value == 0) {
          $timeout(function () {
            angular.element("#tabProjectstd").addClass("disabled");
          }, 10);
        }
        else {
          $timeout(function () {
            angular.element("#tabProjectstd").removeClass("disabled");
          }, 10);
        }
      }
      if (String(tab) === 'valuationPortfolio') {
        if (value == 0) {
          $timeout(function () {
            angular.element("#tabValuationPortfolio").addClass("disabled");
          }, 10);
        }
        else {
          $timeout(function () {
            angular.element("#tabValuationPortfolio").removeClass("disabled");
          }, 10);
        }
      }
      else if (String(tab) === 'report') {
        if (value == 0) {
          $timeout(function () {
            angular.element("#tabReport").addClass("disabled");
          }, 20);
        }
        else {
          $timeout(function () {
            angular.element("#tabReport").removeClass("disabled");
          }, 20);
        }
      }
      else if (String(tab) === 'property') {
        if (value == 0) {
          $timeout(function () {
            angular.element("#tabProperty").addClass("disabled");
          }, 10);
        }
        else {
          $timeout(function () {
            angular.element("#tabProperty").removeClass("disabled");
          }, 10);
        }
      } else if (String(tab) === 'valuation') {
        if (value == 0) {
          $timeout(function () {
            angular.element("#tabValuation").addClass("disabled");
          }, 10);
        }
        else {
          $timeout(function () {
            angular.element("#tabValuation").removeClass("disabled");
          }, 10);
        }
      } else if (String(tab) === 'tenant') {
        if (value == 0) {
          $timeout(function () {
            angular.element("#tabTenant").addClass("disabled");
          }, 10);
        }
        else {
          $timeout(function () {
            angular.element("#tabTenant").removeClass("disabled");
          }, 10);
        }
      } else if (String(tab) === 'tabCompetitor') {
        if (value == 0) {
          $timeout(function () {
            angular.element("#tabCompetitor").addClass("disabled");
          }, 10);
        }
        else {
          $timeout(function () {
            angular.element("#tabCompetitor").removeClass("disabled");
          }, 10);
        }
      } else if (String(tab) === 'tasks') {
        if (value == 0) {
          $timeout(function () {
            angular.element("#tabTasks").addClass("disabled");
          }, 20);
        }
        else {
          $timeout(function () {
            angular.element("#tabTasks").removeClass("disabled");
          }, 20);
        }
      }
    }

    /**
     * @ngdoc function
     * @name onTabCreated
     * @methodOf DXSPolluxApp.component:projectValuationWorkplace
     * @description
     * Called if a tab was created. Stores an internal reference to this tab.
     * 
     * @param {Object} tab The tab that was created
     */
    function onTabCreated(tab) {
      vm.tabs[tab.tabElement.tabIndex] = tab;

      if (tab.tabElement.tabIndex == 0) {
        //init selectedImportArea for first tab, which is active by default
        vm.selectedImportArea = tab.tabElement.exportAreaName || '';
        vm.main.setSelectedImportArea(tab.tabElement.exportAreaName || '');
      }
    }

    /**
     * @ngdoc function
     * @name onElementSelected
     * @methodOf DXSPolluxApp.component:projectValuationWorkplace
     * @description
     * Called if an item was selected in any tab of the current area.
     * 
     * @param {Object} tabItem The source tab
     * @param {Object} item The item that was selected
     */
    function onElementSelected(tabItem, item, filtersEx) {
      if (!tabItem.isLeafHierarchy) {
        //This tab has filtered tabs

        try {
          if (vm.previouslySelectedTabItem && vm.previouslySelectedItem) {
            if (vm.previouslySelectedTabItem == JSON.stringify(tabItem) && vm.previouslySelectedItem == JSON.stringify(item)) {
              return;
            }
          }

          vm.previouslySelectedTabItem = JSON.stringify(tabItem);
          vm.previouslySelectedItem = JSON.stringify(item);
        } catch (e) {
          //Nothing
        }
        vm.selectedTabHierarchy = tabItem.tabHierarchy;
        vm.main.selectedTabHierarchy = tabItem.tabHierarchy;
        vm.selectedTabHierarchyItem = item;
        vm.main.selectedTabHierarchyItem = item;
        vm.selectedTabHierarchyItemType = tabItem.searchMode;
        vm.main.selectedTabHierarchyItemType = tabItem.searchMode;
        if (vm.mds.comparasionJobsExists()) {
          vm.mds.reset();
        }
        angular.forEach(vm.tabs, function (tab) {
          if (typeof (tab.filter) === 'function') {
            var filterObjEx = null;
            if (filtersEx && filtersEx.length) {
              filterObjEx = filtersEx.filter(f => f.tabId === tab.tabElement.id);
              filterObjEx = filterObjEx.length ? filterObjEx[0].filter : null;
            }

            if (vm.selectedTabHierarchyItem.length > 0) {
              tab.filter(vm.selectedTabHierarchyItem[0], vm.selectedTabHierarchy, vm.tabConfig, /*reset*/undefined, filterObjEx);
            }
            else {
              tab.filter(vm.selectedTabHierarchyItem, vm.selectedTabHierarchy, vm.tabConfig, /*reset*/undefined, filterObjEx);
            }

          }
        });
      }

      vm.main.onElementSelected(tabItem, item);
    }

    /**
     * @ngdoc function
     * @name onElementDeselected
     * @methodOf DXSPolluxApp.component:projectValuationWorkplace
     * @description
     * Called if an item was deselected in any tab of the current area.
     * 
     * @param {Object} tabItem The source tab
     */
    function onElementDeselected(tabItem) {
      if (tabItem.isLeafHierarchy) {
        //This tab doesn't have any filtered tabs
        return;
      }
      if (vm.selectedTabHierarchy == tabItem.tabHierarchy) {
        vm.previouslySelectedTabItem = null;
        vm.previouslySelectedItem = null;
        vm.selectedTabHierarchy = null;
        vm.main.selectedTabHierarchy = null;
        vm.selectedTabHierarchyItem = null;
        vm.main.selectedTabHierarchyItem = null;
        vm.selectedTabHierarchyItemType = null;
        vm.main.selectedTabHierarchyItemType = null;

        angular.forEach(vm.tabs, function (tab) {
          if (typeof (tab.filter) === 'function') {
            tab.filter(vm.selectedTabHierarchyItem, vm.selectedTabHierarchy, vm.tabConfig);
          }
        });

        vm.main.onElementDeselected(tabItem, item);
      }
    }

    /**
     * @ngdoc function
     * @name onShowAll
     * @methodOf DXSPolluxApp.component:projectValuationWorkplace
     * @description
     * Called if the show all option was chosen by the user. Resets the search mode and shows all items in all tabs
     * 
     * @param {Object} tabItem The source tab. Not used any more
     */
    function onShowAll(tabItem) {
      vm.selectedTabHierarchy = null;
      vm.main.selectedTabHierarchy = null;
      vm.selectedTabHierarchyItem = null;
      vm.main.selectedTabHierarchyItem = null;
      vm.selectedTabHierarchyItemType = null;
      vm.main.selectedTabHierarchyItemType = null;

      angular.forEach(vm.tabs, function (tab) {
        if (typeof (tab.filter) === 'function') {
          tab.filter(vm.selectedTabHierarchyItem, vm.selectedTabHierarchy, vm.tabConfig, true);
        }
      });
    }

    /**
     * @ngdoc function
     * @name doExport
     * @methodOf DXSPolluxApp.component:projectValuationWorkplace
     * @description
     * Starts the Excel export for the currently selected area.
     * 
     * @param {String} func The name of the function you want to call (Optional).
     */
    function doExport(func) {
      angular.forEach(vm.tabs, function (tab) {
        if (vm.selectedImportArea === tab.tabElement.exportAreaName) {
          if (func) {
            if (typeof (tab[func]) === 'function') {
              tab[func]();
            }
          } else if (typeof (tab.doExport) === 'function') {
            tab.doExport(tab.tabElement.exportAreaName);
          }
        }
      });
    }

    /**
   * @ngdoc function
   * @name doExport
   * @methodOf DXSPolluxApp.component:projectValuationMain
   * @description
   * Initiates an Excel export if the currently selected area supports this, using the Kendo UI grid's functinalities
   */
    function doGridExcelExport() {
      angular.forEach(vm.tabs, function (tab) {
        if (vm.selectedImportArea === tab.tabElement.exportAreaName) {
          if (typeof (tab.doGridExcelExport) === 'function') {
            tab.doGridExcelExport();
          }
        }
      });
    }

    function closeDqs(e) {
      vm.dqsData = null;
    }

    function closeDashboard() {
      resetDashboardData();
      $rootScope.$broadcast('hideDashboard');
    }

    function dashboardGoForward() {
      $rootScope.$broadcast('dashboardSelectNextJob', vm.dashboardData);
    }

    function dashboardGoBack() {
      $rootScope.$broadcast('dashboardSelectPreviousJob', vm.dashboardData);
    }

    vm.publishManager = {
      execute: function (jobEntity, action, callback) {
        switch (action) {
          case "publish":
          case "unpublish":
            {
              debugger;
              const publishParams = {
                share: action === 'publish',
                jobEntity: jobEntity,
                successCallback: function () {
                  jobEntity.IsCTShared = action === 'publish';
                  jobEntity.PublishedCalcModelDirty = false;
                  jobEntity.IsCTSyncronizationOn = action === 'publish';
                  callback && callback(true);
                },
                failCallback: function (msg) {
                  appConfirmDialogService.open({ simpleMessage: true }, msg, 'Information');
                  callback && callback(false);
                }
              };

              $rootScope.$broadcast('CTShareJob', publishParams);
              break;
            }
          case "syncronization": {
            debugger;
            const syncParams = {
              jobEntity: jobEntity,
              syncronizationOn: !jobEntity.IsCTSyncronizationOn,
              successCallback: function () {
                jobEntity.IsCTSyncronizationOn = !jobEntity.IsCTSyncronizationOn;
                const onOff = jobEntity.IsCTSyncronizationOn ? 'on' : 'off';
                appConfirmDialogService.open({ simpleMessage: true }, `Syncronization ${onOff}`, 'Syncronization');
                callback && callback(true);
              },
              failCallback: function (msg) {
                appConfirmDialogService.open({ simpleMessage: true }, msg, 'Information');
                callback && callback(false);
              }
            };

            $rootScope.$broadcast('CTShareJobSyncronization', syncParams);
            break;
          }
        }
      }
    }

    //#region "EVENTS"

    $scope.$on("kendoWidgetCreated", function (event, widget) {
      // the event is emitted for every widget; if we have multiple
      // widgets in this controller, we need to check that the event
      // is for the one we're interested in.
      if (widget === vm.tabComponent) {
        //tabComponent is created
        vm.showTabs = true;
      }
    });

    $scope.$on('startArgusImport', function (event, item) {
      angular.forEach(vm.tabs, function (tab) {
        if (tab.tabElement.searchMode === vm.searchMode) {
          if (typeof (tab.argusImport) === 'function') {
            tab.argusImport();
          }
        }
      });
    });

    $scope.$on('startOverview', function (event, item) {
      angular.forEach(vm.tabs, function (tab) {
        if (tab.tabElement.searchMode === vm.searchMode) {
          if (typeof (tab.startOverview) === 'function') {
            tab.startOverview();
          }
        }
      });
    });

    $scope.$on('localeChanged', function (event, item) {
      angular.forEach(vm.tabs, function (tab) {
        if (typeof (tab.updateLocale) === 'function') {
          tab.updateLocale(item);
        }
      });
    });

    $scope.$on('valuationTabSelect', function (event, item) {
      //console.log("tabComponent.select: #" + item);

      //close all filter panes if open (Bug 5384: Pane stays open when navigation)
      $rootScope.$broadcast('toggleAreaFilters', { forceClose: true });

      var $tab = $("#" + item);
      //console.log($tab.length);
      vm.tabComponent.enable($tab, true);
      vm.tabComponent.select($tab);
      vm.tabComponent.activateTab($tab);
      vm.dqsData = null;

      if (item === "tabValuationPortfolio") {
        $scope.$broadcast('valuationPortfolioLoadData');
      }
      else if (item === "tabProperty") {
        $scope.$broadcast('PropertyLoadData');
      }

      resetDashboardData();
    });

    $scope.$on('valuationProjectPortfolioItemSaved', function (event, item) {
      if (vm.searchMode !== 'valuationPortfolio') {
        vm.tabComponent.select('#tabValuationPortfolio');
      }
    });

    $scope.$on('showDqs', function (event, dqsData) {
      vm.dqsData = dqsData;
    });

    $scope.$on('showDashboard', function (event, dashboardData) {
      resetDashboardData();
      vm.dashboardData = dashboardData;
    });

    $scope.$on('setDashboardComparisonMode', function (event, params) {
      vm.isDashboardComparisonMode = params.mode;
      if (vm.isDashboardComparisonMode) {
        $timeout(function () {
          //alert("ComparisonMode ON");
        });
      }
    });

    $scope.$on('hideDashboard', function () {
      resetDashboardData();
    });

    function resetDashboardData() {
      vm.dashboardData = null;
      vm.isDashboardComparisonMode = false;
    }

    //#endregion "EVENTS"

    vm.gridsterScrollTop = 0;

    vm.onScrollTop = function (scrollTop) {
      console.log(scrollTop);
    }
  }
}());
