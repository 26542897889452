(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:projectValuationComparisonJobsDialog
   * @description
   * Dialog for comparison jobs selection
   * 
   * @requires $scope
   * @requires $rootScope
   * @requires $translate
   *
   */
  angular.module("DXSPolluxApp")
    .component("projectValuationComparisonJobsDialog", {
      template: require('html-loader!./project.valuation.comparison.jobs.dialog.tmpl.html'),
      controllerAs: "vm",
      controller: ['$q', '$scope', '$timeout', '$interval', 'reflistResource', 'multiDashboardService', 'qvPropertyImagesService', 'appUserAvatarService', 'ValuationPortfolioTeamResource', projectValuationComparisonJobsDialogCtrl]
    });

  function projectValuationComparisonJobsDialogCtrl($q, $scope, $timeout, $interval, reflistResource, multiDashboardService, qvPropertyImagesService, appUserAvatarService, ValuationPortfolioTeamResource) {
    var vm = this;
    const mds = multiDashboardService;
    const pis = qvPropertyImagesService;

    var ref_job_status = [];
    var ref_valuation_project_type = [];

    vm.comparableJobs = [];

    //TODO: Why isn't this in OnInit?
    mds.comparableJobs$.subscribe((jobs) => {
      $timeout(() => {
        vm.comparableJobs = jobs;
        loadPicture();

        if (vm.comparableJobs && vm.comparableJobs.length) {
          vm.comparableJobs.forEach((job) => {
            let ref_job_status_entry = ref_job_status.filter((entry) => { return entry.oid === job.JobStatusId; });
            if (ref_job_status_entry.length) {
              job.JobStatusDescription = ref_job_status_entry[0].description;
            }

            let ref_valuation_project_type_entry = ref_valuation_project_type.filter((entry) => { return entry.oid === job.JobTypeId; });
            if (ref_valuation_project_type_entry.length) {
              job.JobTypeDescription = ref_valuation_project_type_entry[0].description;
            }
            $q.all([
              ValuationPortfolioTeamResource.loadJobCurrentTeamUsers(job.Oid)
            ]).then(function (results) {
              setupMembers(results[0], job);
            }).catch(() => {
              //ignore
            });
          });
        }
      });
    });

    vm.window = null;
    vm.loaded = false;
    vm.imageDataUrl = null;//TODO: all jobs have same property, so always same image???
    vm.comparableJobs = [];

    const windowWidth = 400;
    const openCloseDuration = 200;

    vm.options = {
      title: false,
      width: windowWidth + "px",
      draggable: false,
      resizable: false,
      modal: false,
      position: {
        top: 0,
        left: 0
      },
      animation: {
        open: {
          effects: "slideIn:left",
          duration: openCloseDuration
        },
        close: {
          effects: "slideIn:left",
          reverse: true,
          duration: openCloseDuration
        }
      },
      visible: false
    };

    function setupMembers(members, job) {
      job.teamMembers = members;
      members.forEach((user) => {
        user.initials = appUserAvatarService.getInitials(user.description);
        return user;
      });

      //sort by description ASC
      members.sort((a, b) => (a.description > b.description) ? 1 : ((b.description > a.description) ? -1 : 0));
    }

    function reset() {
      vm.loaded = false;
      vm.imageDataUrl = null;

      const toolbar = document.getElementById("project-valuation-toolbar").getBoundingClientRect();

      const winW = window.innerWidth;
      const winH = window.innerHeight;

      if (vm.window) {
        vm.window.wrapper.css("top", (toolbar.height) + "px");
        vm.window.wrapper.css("left", (winW - windowWidth) + "px");
        vm.window.wrapper.css("height", (winH - toolbar.height) + "px");
      }
    }

    reset();

    vm.cancel = cancel;
    vm.jobClicked = jobClicked;
    vm.getProperyName = getProperyName;
    vm.getProperyAddress = getProperyAddress;

    /**
    * @ngdoc function
    * @name cancel
    * @methodOf DXSPolluxApp.component:projectValuationProjectStdSettingsInternalUsersDialog
    * @description
    * Closes the dialog
    */
    function cancel() {
      reset();
      vm.window.close();
    }

    function jobClicked(job) {
      mds.addJob(job);
      if (vm.comparableJobs.length === 1) {
        vm.cancel();//last one was added
      }
    }

    function getProperyName(job) {
      //taken from CT
      const parts = (job.LinkedPropertyAddress || '').split(',');
      const propertyName = parts[0];
      return propertyName;
    }

    function getProperyAddress(job) {
      //taken from CT
      const parts = (job.LinkedPropertyAddress || '').split(',');
      const propertyName = parts[0];
      let propertyAddress = '';
      if (parts.length > 1) {
        parts.shift();
        propertyAddress = parts.join(' |');
      }

      return propertyAddress;
    }

    function loadPicture() {
      if (vm.comparableJobs && vm.comparableJobs.length) {
        const propertyId = vm.comparableJobs[0].LinkedPropertyOid;
        const sub = pis.loadInfo(propertyId).subscribe((infos) => {
          sub.unsubscribe();
          if (infos.length) {
            const pictureId = infos[0].Oid;
            loadImageDataUrl(propertyId, pictureId);
          }
        });
      }
    }

    function loadImageDataUrl(propertyId, pictureId) {
      const sub = pis.loadImageDataUrl(pictureId, 328, 204).subscribe((imageDataUrl) => {
        sub.unsubscribe();
        $timeout(() => {
          vm.imageDataUrl = imageDataUrl;
        });
      });
    }

    //#region "EVENTS"
    $scope.$on('closeSidebar', function (event, params) {
      cancel();
    });

    $scope.$on('openProjectValuationComparisonJobsDialog', function (event, params) {
      $q.all([
        reflistResource.loadPollux.query({ reflistName: 'ref_job_status' }).$promise,
        reflistResource.loadPollux.query({ reflistName: 'ref_valuation_project_type' }).$promise
      ]).then(function (result) {
        vm.ref_job_status = result[0];
        vm.ref_valuation_project_type = result[1];
        reset();
        loadPicture();

        $timeout(() => {

          vm.window.open();
          $timeout(() => {
            vm.loaded = true;
          });

        });
      });
    });

    //#endregion "EVENTS"
  }
}());

