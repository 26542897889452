(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:projectValuationToolbar
   * @description
   * Toolbar for the Project Valuation (Valuation Portfolio) area.
   * 
   * @requires appRouting
   * @requires $filter
   * @requires $translate
   * @requires $rootScope
   * @requires $timeout
   * @requires $scope
   * @requires DXSPolluxApp.service:appStorage
   * @requires DXSPolluxApp.service:appLocalization
   * @requires $http
   *
   * 
   * 
   * @requires DXSPolluxApp.component:projectValuationMain
   */
  angular.module("DXSPolluxApp")
    .component("projectValuationToolbar", {
      template: require('html-loader!./project.valuation.toolbar.tmpl.html'),
      require: {
        "main": "^projectValuationMain"
      },
      controllerAs: "vm",
      controller: ['appRouting', '$filter', '$translate', '$rootScope', '$timeout', '$scope', '$http', 'appStorage', 'appLocalization', 'appSettings', projectValuationToolbarCtrl]
    });

  function projectValuationToolbarCtrl(appRouting, $filter, $translate, $rootScope, $timeout, $scope, $http, appStorage, appLocalization, appSettings) {
    var vm = this;
    vm.isProjectSelected = () => !!appStorage.getSelectedProject();

    vm.search = search;
    vm.currentArea = "projectstd";
    vm.toolbarOptions = null;
    vm.areaConfig = null;
    vm.infobarMode = false;
    vm.initialized = false;
    vm.searchText = 'Test';
    vm.searchActive = false;
    vm.filterActive = false;
    vm.showDashboard = false;
    vm.helpTextActive = false;

    vm.isDashboardComparisonMode = false;
    vm.dashboardComparisonModeClicked = dashboardComparisonModeClicked;

    /**
     * @ngdoc function
     * @name $onInit
     * @methodOf DXSPolluxApp.component:projectValuationToolbar
     * @description
     * Initializes the toolbar
     */
    vm.$onInit = function () {
      $http.get('config/AreaDefinitions.json').then(function (result) {
        vm.areaConfig = result.data;
        var items = getTabItems();

        vm.toolbarOptions = {
          items: items,
          click: function (e) {
            switch (e.id) {
              case 'buttonToggleLocale': {
                $timeout(function () {
                  appLocalization.toggleLocale();

                  vm.toolbarOptions.items = getTabItems();
                }, 10);
                break;
              }
              case 'buttonTogglePVSearchMode': {
                vm.searchText = '';
                vm.searchActive = !vm.searchActive;

                if (!vm.searchActive) {
                  $rootScope.$broadcast('project.valuation.showAll');
                }

                break;
              }
              case 'buttonToggleAreaFilters': {
                $rootScope.$broadcast('toggleAreaFilters', { area: vm.currentArea });
                break;
              }
              case 'buttonOpenUserProfile': {
                $rootScope.$broadcast('openUserProfile');
                break;
              }
              case 'buttonHelpText': {
                vm.helpTextActive = !vm.helpTextActive;
                $rootScope.$broadcast('helpText');
                break;
              }
              default: {
                alert("The toolbar button " + e.id + " is not configured!");
              }
            }
          },
          toggle: function (e) {
            switch (e.id) {
              case 'buttonTogglePVInfoMode': {
                $timeout(function () {
                  vm.main.setPVInfoBarVisibility(e.checked, false);
                });
                break;
              }
              case 'buttonShowDashboard': {
                if (e.checked) {
                  $rootScope.$broadcast("showDashboardSelect");
                }
                else {
                  $timeout(function () {
                    $rootScope.$broadcast('hideDashboard');
                    vm.showDashboard = false;
                    vm.toolbarOptions.items = getTabItems();
                  });
                }
                break;
              }

              default: {
                alert("The toolbar button " + e.id + " is not configured!");
              }
            }
          }
        };
        vm.initialized = true;
      });
    };

    $scope.$on('toolbarSetArea', function (event, newArea) {
      vm.currentArea = newArea;
      vm.searchText = '';
      vm.searchActive = false;

      $rootScope.$broadcast("broadcastFiltersActiveState", { area: vm.currentArea });
      vm.toolbarOptions.items = getTabItems();
    });

    $scope.$on('filtersActiveState', function (event, params) {
      if (params.area === vm.currentArea) {
        vm.filterActive = params.active;
        setFilterButtonActive(params.active);
      }
    });

    $scope.$on('localeChanged', function () {
      vm.toolbarOptions.items = [];
      $timeout(function () {
        vm.toolbarOptions.items = getTabItems();
      });
    });

    function search() {
      var filter = {
        logic: "or",
        filters: [],
        serverFilters: []
      };

      if (vm.currentArea === 'valuationPortfolio') {
        filter.filters.push({ field: "Oid", operator: "contains", value: vm.searchText });

        filter.filters.push({ field: "ValuationPortfolioName", operator: "contains", value: vm.searchText });

        filter.filters.push({ field: "ProjectIDDescription", operator: "contains", value: vm.searchText });

        filter.filters.push({ field: "ValuationDate", operator: "contains", value: vm.searchText });

        filter.filters.push({ field: "Remark", operator: "contains", value: vm.searchText });

        filter.filters.push({ field: "ClientIdDescription", operator: "contains", value: vm.searchText });

        filter.filters.push({ field: "OwnerUserIDDescription", operator: "contains", value: vm.searchText });

        filter.filters.push({ field: "ModifiedByUserIDDescription", operator: "contains", value: vm.searchText });  

        $rootScope.$broadcast('project.valuation.search', filter);
      }
      else if (vm.currentArea === 'property') {
        filter.filters.push({ field: "ProjectStdIDDescription", operator: "contains", value: vm.searchText });

        filter.filters.push({ field: "MunicipalManual", operator: "contains", value: vm.searchText });

        filter.filters.push({ field: "PostalCodeManual", operator: "contains", value: vm.searchText });

        filter.filters.push({ field: "Street1", operator: "contains", value: vm.searchText });

        filter.filters.push({ field: "AssetClass", operator: "contains", value: vm.searchText });

        $rootScope.$broadcast('project.valuation.property.search', filter);
      }
    }

    /**
     * @ngdoc function
     * @name getTabItems
     * @methodOf DXSPolluxApp.component:projectValuationToolbar
     * @description
     * Gets all toolbar actions that are visible in the currently selected tab and enables them in the toolbar.
     * @returns {Array} The toolbar items
     */
    function getTabItems() {
      var isProjectTab = ",projectstd,".indexOf(',' + vm.currentArea + ',') >= 0;
      var isOrganizationTab = ",organization,".indexOf(',' + vm.currentArea + ',') >= 0;
      var isValuationPortfolioTab = ",valuationPortfolio,".indexOf(',' + vm.currentArea + ',') >= 0;

      var filterButtonClasses = "align-right" + (vm.filterActive ? " active" : "");
      var profileButtonClasses = "align-right";
      var helpTextButtonClasses = "align-right" + (vm.helpTextButtonClasses ? " active" : "");

      var items = [
        { template: '<smart-bar [main]="vm.main"></smart-bar>' },

        { template: "<input ng-if='vm.searchActive' type='text' id='searchText' class='k-textbox' ng-model='vm.searchText' ng-model-options='{ debounce: 1000 }' ng-change='vm.search()' style='width: 250px;' default-focus>", hidden: !vm.searchActive },
        { type: "button", id: 'buttonTogglePVSearchMode', icon: "k-icon k-i-zoom", enable: true, attributes: { title: $filter('uppercase')($translate.instant('projectToolbar.buttonTogglePVSearchMode')) }, hidden: isProjectTab || isOrganizationTab },
        { type: "button", id: 'buttonToggleAreaFilters', icon: "k-icon k-i-filter", enable: true, attributes: { title: $filter('uppercase')($translate.instant('projectToolbar.buttonToggleAreaFilters')), "class": filterButtonClasses }, hidden: isProjectTab || isOrganizationTab },

        {
          template: "<img id='buttonToggleDashboardComparisonMode' src='../../../../content/images/ionicons/game-controller-outline.svg' height='32' width='32' align='middle' \
                    ng-if='vm.showDashboard' ng-click='vm.dashboardComparisonModeClicked()' ng-class='{\"active\": vm.isDashboardComparisonMode}' \
                    >" },

        { type: "button", id: 'buttonOpenUserProfile', icon: "k-icon k-i-user", enable: true, attributes: { title: $filter('uppercase')($translate.instant('projectToolbar.buttonOpenUserProfile')), "class": profileButtonClasses }, hidden: false },
        { type: "button", id: 'buttonHelpText', icon: "icon ion-help", enable: true, attributes: { title: $filter('uppercase')($translate.instant('projectToolbar.buttonHelpText')), "class": helpTextButtonClasses }, hidden: false }
      ];

      if (appSettings.environment.tasksLayoutModeQv) {
        items = items.filter(o => !o.id || o.id.indexOf("buttonTask") !== 0);
      }

      //Add the localization buttons
      angular.forEach(vm.areaConfig, function (area) {
        if (area.id !== 'ProjectValuation' && area.show) {
          if (area.id === 'Settings' && !appStorage.userIsInRole(['Admin'])) {
            return;
          }
          var item = {
            type: "button", overflow: "always", overflowTemplate: area.template
          };
          items.push(item);
        }
      });

      return items;
    }

    function setFilterButtonActive(active) {
      let filterButton = document.getElementById("buttonToggleAreaFilters");
      if (filterButton) {
        if (active) {
          filterButton.classList.add("active");
        } else {
          filterButton.classList.remove("active");
        }
      }
    }

    function reset() {
      vm.showDashboard = false;
      vm.toolbarOptions.items = getTabItems();
    }

    function dashboardComparisonModeClicked() {
      $rootScope.$broadcast('setDashboardComparisonMode', { mode: !vm.isDashboardComparisonMode });
    }

    $scope.$on('setDashboardComparisonMode', function (e, params) {
      vm.isDashboardComparisonMode = params.mode;
    });

    $scope.$on('project-valuation-infobar-changed', function (e, mode) {
      vm.infobarMode = mode.mode;

      if (vm.initialized) {
        vm.toolbarOptions.items = getTabItems();
      }
    });

    $scope.$on('search-bar-visibility-changed', function () {
      vm.toolbarOptions.items = getTabItems();
    });

    $scope.$on('showDashboard', function (event, dashboardData) {
      vm.showDashboard = true;
      vm.isDashboardComparisonMode = false;
      vm.toolbarOptions.items = getTabItems();
    });

    $scope.$on('hideDashboard', function (event, dashboardData) {
      vm.showDashboard = false;
      vm.toolbarOptions.items = getTabItems();
    });

    /* close handling*/
    $scope.$on("toggleAreaFilters", function (event, params) {
      params.forceClose && reset();
    });
  }
}());
