(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:projectValuationProjectStdSettingsInternalUsersDialog
   * @description
   * Dialog for project settings internal user selection
   * 
   * @requires $scope
   * @requires $rootScope
   * @requires $translate
   *
   */
  angular.module("DXSPolluxApp")
    .component("projectValuationProjectStdSettingsInternalUsersDialog", {
      template: require('html-loader!./project.valuation.projectstd.settings.internal.users.dialog.tmpl.html'),
      controllerAs: "vm",
      controller: ['$rootScope', '$scope', '$compile', '$q', '$timeout', 'ProjectsResourceCustom', projectValuationProjectStdSettingsInternalUsersDialogCtrl]
    });

  function projectValuationProjectStdSettingsInternalUsersDialogCtrl($rootScope, $scope, $compile, $q, $timeout, ProjectsResourceCustom) {
    var vm = this;
    vm.window = null;

    vm.availableUsersDataSource = new kendo.data.HierarchicalDataSource({
      data: []
    });

    vm.selectedUsersDataSource = new kendo.data.HierarchicalDataSource({
      data: []
    });

    vm.selectOptionsSelectedUsers = {
      dataTextField: "descriptionEnglish",
      dataValueField: "userId",
      dataSource: vm.selectedUsersDataSource,
      template: selectedUsersTemplate,
      dataBound: compileTemplate, //http://dojo.telerik.com/@bubblemaster/IQatA/2
      add: compileTemplate
    };

    vm.selectOptionsAvailableUsers = {
      dataTextField: "descriptionEnglish",
      dataValueField: "userId",
      dataSource: vm.availableUsersDataSource,
      template: availableUsersTemplate,
      dataBound: compileTemplate, //http://dojo.telerik.com/@bubblemaster/IQatA/2
      add: compileTemplate
    };

    function reset() {
      vm.project = null;
      vm.loaded = false;
      vm.selectedUsersDataSource.data([]);
      vm.availableUsersDataSource.data([]);

      const toolbar = document.getElementById("project-valuation-toolbar").getBoundingClientRect();

      const windowWidth = 400;
      vm.options = {
        title: false,
        width: windowWidth + "px",
        draggable: false,
        resizable: false,
        modal: true,
        position: {
          top: toolbar.height,
          left: 0
        },
        animation: {
          open: {
            effects: "slideIn:left",
            duration: 200
          },
          close: {
            effects: "slideIn:left",
            reverse: true,
            duration: 200
          }
        },
        visible: false,
        activate: function () {
          // Close window when clicked outside of window
          angular.element(".k-overlay").on("click",
            function () {
              if (vm.window) {
                $timeout(function () {
                  vm.cancel();
                });
              }
            });
        }
      };

      const winW = window.innerWidth;
      const winH = window.innerHeight;
      vm.options.position.left = winW - windowWidth;
      vm.options.height = winH - toolbar.height;
    }

    reset();

    vm.cancel = cancel;
    vm.plusButtonClicked = plusButtonClicked;
    vm.minusButtonClicked = minusButtonClicked;

    /**
    * @ngdoc function
    * @name cancel
    * @methodOf DXSPolluxApp.component:projectValuationProjectStdSettingsInternalUsersDialog
    * @description
    * Closes the dialog
    */
    function cancel() {
      vm.window.close();
    }

    function plusButtonClicked(id) {
      var user = vm.availableUsersDataSource.data().filter((item) => { return item.userId === id; });
      if (user && user.length) {
        $q.all([
          ProjectsResourceCustom.addInternalUser(vm.project.Oid, id)
        ]).then(function (results) {
          vm.availableUsersDataSource.data(vm.availableUsersDataSource.data().filter((item) => { return item.userId !== id; }));
          vm.selectedUsersDataSource.data().push(user[0]);

          $rootScope.$broadcast('projectMembersChanged', { projectId: vm.project.Oid, teamMembers: vm.selectedUsersDataSource.data() });
        }).catch(() => {
        });
      }
    }

    function minusButtonClicked(id) {
      var user = vm.selectedUsersDataSource.data().filter((item) => { return item.userId === id; });
      if (user && user.length) {
        $q.all([
          ProjectsResourceCustom.removeInternalUser(vm.project.Oid, id)
        ]).then(function (results) {
          vm.selectedUsersDataSource.data(vm.selectedUsersDataSource.data().filter((item) => { return item.userId !== id; }));
          vm.availableUsersDataSource.data().push(user[0]);

          $rootScope.$broadcast('projectMembersChanged', { projectId: vm.project.Oid, teamMembers: vm.selectedUsersDataSource.data() });
        }).catch(() => {
        });
      }
    }

    function compileTemplate(e) {
      var listbox = this;
      $timeout(function () {
        var rows = listbox.items();
        rows.each(function (index, row) {
          if (!$(row).hasClass("ng-scope")) {
            $compile(row)($.extend($scope.$new(), listbox.dataItem(row)));
          }
        });
      });
    }

    function availableUsersTemplate(dataItem) {
      return `
<div class="item-row">
  <div class="item-text">
    ${dataItem.userDescription}
  </div>
  <span class="item-button k-icon k-i-plus" ng-click="vm.plusButtonClicked(${dataItem.userId})"></span>
</div> 
`;
    }

    function selectedUsersTemplate(dataItem) {
      return `
<div class="item-row">
  <div class="item-text item-text-cyan">
    ${dataItem.userDescription}
  </div>
  <span class="item-button k-icon k-i-minus" ng-click="vm.minusButtonClicked(${dataItem.userId})"></span>
</div> 
`;
    }

    //#region "EVENTS"

    $scope.$on('openProjectStdSettingsInternalUsersDialog', function (event, params) {
      reset();

      vm.project = params.project;
      const projectId = vm.project.Oid;
      const organizationId = 1;

      setTimeout(() => {
        
        $q.all([
          ProjectsResourceCustom.loadInternalUsers(projectId),
          ProjectsResourceCustom.loadOrganizationUsers(projectId)
        ]).then(function (results) {
          kendo.ui.progress(vm.window.element, false);

          //TODO any desired order?
          const selectedUsers = results[0];
          const organizationUsers = results[1];

          vm.selectedUsersDataSource.data(
            selectedUsers
          );
          vm.selectedUsersDataSource.fetch();

          if (organizationUsers) {
            // only show users of the same organization that are not already selected
            vm.availableUsersDataSource.data(
              organizationUsers.filter((item) => { return item.organizationId === organizationId && !selectedUsers.some(selectedUser => selectedUser.userId === item.userId); })
            );
            vm.availableUsersDataSource.fetch();
          }

          vm.loaded = true;
        }).catch(() => {
          kendo.ui.progress(vm.window.element, false);
        });

        vm.window.open();
      });
    });
      
    //#endregion "EVENTS"
  }
}());

